@use "sass:meta" as ---bvzfpbmpmse;// colors
$c-transparent: transparent;
$c-current: currentColor;

$c-text: #343460;
$c-white: #fff;
$c-black: #000;
$c-primary: #485268;
$c-headings: #333A4A;
$c-secondary: #8b93a6;
$c-dark-gray: #c2c2c2;
$c-icons: #bfc5d1;
$c-elements: #dee2ed;
$c-red: #e25544;
$c-focus-red: #c90000;
$c-light-red: #ffedeb;
$c-dark-red: #de2d18;
$c-blue: #22b0fc;
$c-navy: #225ffc;
$c-light-cyan: #e5f3f4;
$c-semi-light-cyan: #B2DADE;
$c-medium-cyan: #CCE7E9;
$c-dark-cyan: #507D81;
$c-light-blue: #e9f7ff;
$c-cyan: #D1E6EC;
$c-light-navy: #edf1ff;
$c-dark-blue: #212631;
$c-green: #38c976;
$c-lemon-green: #C2E549;
$c-light-green: #ebf9f1;
$c-dark-green: #07803b;
$c-yellow: #fec132;
$c-bright-yellow: #ffbe15;
$c-light-yellow: #fff9eb;
$c-dark-yellow: #d6a022;
$c-purple: #7557e6;
$c-purple-medium: #DED8F6;
$c-purple-dark: #5b36e4;
$c-purple-accent: #5955F4;
$c-light-purple: #f1eefc;
$c-pink: #eb67d0;
$c-light-pink: #fdf0fb;
$c-orange: #ff7246;
$c-light-orange: #fff1ed;
$c-gray: #f5f5f5;
$c-medium-gray: #e7ebf4;
$c-semi-light-gray: #ebeef5;
$c-rose: #f24f87;

// red colors
$c-red-130: #D12B17;
$c-red-120: #DA3824;
$c-red-110: #E24734;
$c-red-90: #E77062;
$c-red-15: #FFE4E1;

// gray colors
$c-gray-100: #0F0F10;
$c-gray-90: #18181A;
$c-gray-80: #202023;
$c-gray-70: #323236;
$c-gray-65: #464E62;
$c-gray-60: #444449;
$c-gray-50: #686970;
$c-gray-45: #7A8399;
$c-gray-40: #8E8F96;
$c-gray-35: #B3B8C7;
$c-gray-30: #B6B6BC;
$c-gray-25: #D0D5E1;
$c-gray-20: #DBDBE2;
$c-gray-15: #EDEDF2;
$c-gray-10: #F5F6FA;
$c-gray-5: #FAFBFD;

// social
$c-github: #485268;
$c-linkedIn: #2A83B5;
$c-facebook: #304A82;

// buttons
$c-button-text: --c-button-text;
$c-button-background: --c-button-background;
$c-button-border: --c-button-border;

// filters (added for dark mode icon img filter before stable solution is implemented)
$white-filter: invert(100%) sepia(16%) saturate(422%) hue-rotate(201deg) brightness(125%) contrast(100%);
$white-filter-bw: invert(100%) grayscale(100%) brightness(10);


;@include ---bvzfpbmpmse.load-css("sass-embedded-legacy-load-done:83441");