@import "utils";

.tableCell {
  padding: rem-calc(24 12);
  line-height: 0;
  vertical-align: middle;

  &::before {
    @include text-platform-h5;

    content: attr(data-title);
    display: none;
    color: getColor($c-text-tertiary);
  }

  * {
    line-height: normal;
  }

  &:first-child {
    padding: rem-calc(24 12 24 24);
  }

  &:last-child {
    padding: rem-calc(24 24 24 12);
  }

  &.mobile {
    @include breakpoint(medium down) {
      &,
      &:last-child {
        display: block;

        width: auto;
        margin: rem-calc(0 32);
        padding: rem-calc(8 0);
        border-bottom: 1px solid getColor($c-border);
      }

      &:first-child {
        margin: 0;
        padding: rem-calc(24 32);
        border-bottom: none;
        background-color: getColor($c-bg-neutral-tertiary);
      }

      &[data-title] {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::before {
          display: inline;
        }
      }
    }
  }
}

